import { WffCity } from '@wff/types';

const cityToFlagMap = {
  [WffCity.London]: 'GB',
  [WffCity.Madrid]: 'ES',
  [WffCity.Barcelona]: 'ES',
  [WffCity.Lisbon]: 'PT',
  [WffCity.Dublin]: 'IE',
};
export const CITIES = [
  ...Object.values(WffCity).map((city) => {
    return {
      title: city,
      value: cityToFlagMap[city as keyof typeof cityToFlagMap] ?? 'DE',
    };
  }),
];
