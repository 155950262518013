export const cleanPhoneNumber = (phoneNumber: string | undefined) => {
  if (!phoneNumber) return '';
  // at least 6 digits
  if (phoneNumber.length < 6) return '';
  // remove all spaces and `-` and `(` and `)`
  let clean = phoneNumber.replace(/[\s\(\)\-]/g, '');
  // replace `00` with `+`
  clean = clean.replace(/^00/, '+');
  // add + if not present
  if (!clean.startsWith('+')) {
    clean = `+${clean}`;
  }
  return clean;
};

export const phoneNumberMatchingPatternString =
  '^\\+(?:[0-9] ?-?(?:\\(?\\)? ?)){6,14}[0-9]$';

export const validatePhoneNumber = (phoneNumber: string) => {
  const phoneRegex = new RegExp(phoneNumberMatchingPatternString);
  return cleanPhoneNumber(phoneNumber).match(phoneRegex);
};
